import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components'
import { Container } from '../components/styles/Container.styled';
import Header from '../components/Header';
import GlobalStyles from '../components/styles/Global';
import Footer from '../components/Footer';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField2 } from '../components/TextFieldArea';
import { Rating } from 'react-simple-star-rating'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useParams } from "react-router-dom";
import { TextField } from '../components/TextField';
import LoadingPage from '../components/LoadingPage';



const theme = {
    colors: {
        header: '#E6E6FA',
        body: '#fff',
        footer: '#003333',
    },
    mobile: '768px',
}


function softwareChangeForm() {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('Form Filled Successfully ! ! !');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loader, setLoader] = useState(false);

    const { id } = useParams()
    const BASE_URI = 'https://j2wofferletter.com/api/v1/';
    // const BASE_URI = 'http://localhost:3000/api/v1/';

    const handleClose1 = (event) => {
        setShow(false);
        window.location.href = 'https://j2wofferletter.com/'

    };

    const validate = Yup.object({
        page_name: Yup.string()
            .required('Required'),
        type: Yup.string()
            .required('Required'),
        impact: Yup.string()
            .required('Required'),
        level: Yup.string()
            .required('Required'),
        detail: Yup.string()
            .required('Required')
            .min(15, 'Must be 15 characters or more'),
    })

    const call_api = async (values) => {


            setLoader(true)
        const res = await fetch(
            `${BASE_URI}submit_request_form?token=${id}&module=${values.page_name}&req_type=${values.type}&impact=${values.impact}&level=${values.level}&describe=${values.detail}`
            //  `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=${limit}`
        );

        const data = await res.json();

        if (data.status === 1) {
            handleShow()
            // alert('Mail notification is sent')
            setLoader(false)
        }
        else {
            setMessage('Please try again sometime later!!! ')
            handleShow()
            setLoader(false)
            // alert('Mail notification is not sent, Please try again sometime later!!!')
        }

        // console.log(data.data)

    }


    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Header />

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose1} >Understood</Button> */}
                </Modal.Footer>
            </Modal>

            <Container>
                <>

                    {loader ? (<> <LoadingPage /> </>) : (

                        <Formik
                            enableReinitialize
                            initialValues={{
                                page_name: '',
                                type: '',
                                impact: '',
                                level: '',
                                detail: '',
                            }}
                            validationSchema={validate}
                            onSubmit={values => {
                                //  alert(JSON.stringify(values))
                                call_api(values)

                            }}
                        >
                            {({ errors, touched, setFieldValue }) => (
                                <div>
                                    <h2 className="my-4 font-weight-bold .display-2 text-center">Request Form</h2>
                                    <h4 className="my-4 font-weight-bold .display-2 text-left">Initiation of A Change Request</h4>
                                    <Form>
                                        {/* <TextField label="Name" name="name" type="text" /> */}

                                        <TextField label="Module / Page Name" name="page_name" type="text" />

                                        <br /><br></br>
                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Please specify the type of alteration or the team to rectify. </label>
                                        <br />
                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175' }} htmlFor={'type'}>
                                            <Field type="radio" name="type" value="New requirement" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>New requirement</label>
                                        </label>

                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 20 }} htmlFor={'type'}>
                                            <Field type="radio" name="type" value="Requirement alteration" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Requirement alteration</label>
                                        </label>

                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 20 }} htmlFor={'type'}>
                                            <Field type="radio" name="type" value="Bug" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Bug</label>
                                        </label>

                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 20 }} htmlFor={'type'}>
                                            <Field type="radio" name="type" value="MIS" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>MIS Reporting</label>
                                        </label>


                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 20 }} htmlFor={'type'}>
                                            <Field type="radio" name="type" value="IT" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>IT Support</label>
                                        </label>


                                        <ErrorMessage component="div" name={'type'} className="error" />


                                        <br /><br></br>
                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Please specify the reason of the request</label>
                                        <br />
                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175' }} htmlFor={'impact'}>
                                            <Field type="radio" name="impact" value="Business Impact" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Business Impact</label>
                                        </label>

                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 20 }} htmlFor={'impact'}>
                                            <Field type="radio" name="impact" value="Invoice Impact" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Invoice Impact</label>
                                        </label>

                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 20 }} htmlFor={'impact'}>
                                            <Field type="radio" name="impact" value="Tuning the Performance" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Tuning the Performance</label>
                                        </label>
                                        <ErrorMessage component="div" name={'impact'} className="error" />


                                        <br /><br></br>
                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Please specify the level of emergency.</label>
                                        <br />
                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175' }} htmlFor={'level'}>
                                            <Field type="radio" name="level" value="High" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>High (S1)</label>
                                        </label>

                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 20 }} htmlFor={'level'}>
                                            <Field type="radio" name="level" value="Moderate" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Moderate (S2)</label>
                                        </label>

                                        <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 20 }} htmlFor={'level'}>
                                            <Field type="radio" name="level" value="Low" />
                                            <label className="text-decoration-line-through" style={{ fontWeight: 'bold', fontSize: 12, color: '#434175', marginLeft: 5 }}>Low (S3)</label>
                                        </label>
                                        <ErrorMessage component="div" name={'level'} className="error" />


                                        <TextField2 label="Please describe the change. Please include functional and/or technical details" name="detail" type="text" />






                                        <br></br>
                                        <br></br>

                                        <button className="btn btn-dark mt-3 " type="submit">Submit</button>
                                        <button className="btn btn-danger mt-3 ml-3" type="reset">Reset</button>
                                    </Form>

                                </div>
                            )}
                        </Formik>
                    )}

                </>


            </Container>

            <Footer />
        </ThemeProvider>
    )
}

export default softwareChangeForm